<template>
  <div>
    <portal to="body-top">
      <h4 class="text-center font-weight-bolder mb-1">
        {{ $t('New Asset Relocation') }} {{ `—${inventory.sku ? inventory.sku : ''} —${inventory.name ? inventory.name : ''}` }}
      </h4>
    </portal>

    <quote-status-bar
      :item="quote"
      class="mb-1 ml-1"
    />
    <error-alert :error="error" />
    <b-form>
      <validation-observer ref="refPermanentTransferCreateByAsset">
        <skeleton v-if="!inventoryPermanentTransfer" />
        <div v-else>
          <transfer-schedule-form />
          <assets-table />
        </div>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="backToList"
              >
                {{ $t('Back to List') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="cancelBtn"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn d-flex align-items-center font-medium-1 font-weight-bolder"
                @click="submit"
              >
                <feather-icon
                  icon="LTransferCalendarIcon"
                  size="18"
                  class="mr-1"
                />
                {{ $t('Schedule') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-modal
      id="transfer-conflicts-with-orders"
      centered
      ok-variant="success"
      hide-header
      hide-footer
      style="width: 600px"
    >
      <div class="d-flex align-items-center flex-column">
        <h2 class="text-center font-weight-bolder font-medium-5">
          {{ $t('Transfer conflicts with orders:') }}
        </h2>
        <span class="d-flex align-items-center flex-column font-weight-bolder font-medium-5">
          <span>{{ 'RO-4004' }}</span>
          <span>{{ 'RO-4165' }}</span>
        </span>
        <p class="text-center font-small-4 py-1">
          {{ $t('Forcing the transfer will break allocation and further corrective actions will be needed to replenish the missing inventory') }}
        </p>
      </div>
      <span class="d-flex align-items-center justify-content-between">
        <b-button
          variant="outline-primary"
          class="font-medium-1 font-weight-bolder"
          @click="$bvModal.hide('transfer-conflicts-with-orders')"
        >
          {{ $t('Go Back') }}
        </b-button>
        <b-button
          style="background-color: #6F1214 !important"
          class="font-medium-1 font-weight-bolder"
          @click="submitForce"
        >
          {{ $t('Force Transfer') }}
        </b-button>
      </span>
    </b-modal>
    <b-modal
      id="scheduled-transfer-order"
      centered
      ok-variant="success"
      hide-header
      hide-footer
      size="lg"
    >
      <div
        class="text-center"
      >
        <span>
          <feather-icon
            icon="LInOutIcon"
            size="18"
            color="green"
            style="margin-right: 4px; margin-bottom: 4px"
          />
          <h4 class="d-inline font-weight-bolder">
            {{ $t('Next Scheduled Transfer Order') }}
          </h4>
        </span>
        <div>
          <b-table
            :fields="modalTableColumns"
            :items="[...inventoryTransferOrderInformationByAssetId]"
            class="pb-1 px-1"
          >
            <template #cell(order_id)="data">
              <span class="d-block p-0">
                {{ data.item.order_id }}
              </span>
            </template>
            <template #cell(scheduled_time)="data">
              <span class="d-block text-left">
                {{ data.item.scheduled_time }}
              </span>
            </template>
            <template #cell(item_ct)="data">
              <span class="d-block text-left pl-1">
                {{ data.item.items }}
              </span>
            </template>
          </b-table>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <span>
            <b-button
              variant="outline-primary"
              class="font-medium-1 font-weight-bolder"
              @click="goBack"
            >
              {{ $t('Go Back') }}
            </b-button>
          </span>
          <span>
            <b-button
              variant="success"
              class="font-medium-1 font-weight-bolder mr-1"
              @click="submitFormSchedule('force')"
            >
              {{ $t('Create New Instead') }}
            </b-button>
            <b-button
              variant="success"
              class="font-medium-1 font-weight-bolder"
              @click="submitFormSchedule('unite')"
            >
              {{ $t('Add to Order') }}
            </b-button>
          </span>
        </div>
      </div>
    </b-modal>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>

import ErrorAlert from '@/views/components/ErrorAlert.vue'
import {
  BButton, BForm, BModal, BTable,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import jsonToFormData from '@/libs/jsonToFormData'
import { scrollToError } from '@core/utils/utils'
import QuoteStatusBar from '../../components/create/common/QuoteStatusBar.vue'
import AssetsTable from '../../components/create/createTransferByAssetId/AssetsTable.vue'
import TransferScheduleForm from '../../components/create/createTransferByAssetId/TransferScheduleForm.vue'
import config from './config'
import Skeleton from '../../components/create/createTransferByAssetId/Skeleton.vue'

export default {
  name: 'InventoryCreatePermanentTransferByAsset',
  components: {
    BTable,
    BButton,
    BModal,
    AssetsTable,
    TransferScheduleForm,
    QuoteStatusBar,
    ErrorAlert,
    ValidationObserver,
    Skeleton,
    BForm,
  },
  data() {
    return {
      error: {},
      assetId: this.$route.params.id,
      transferAssests: {},
    }
  },
  computed: {
    quote() {
      return this.$store.state[this.MODULE_NAME].quoteTransferOrder
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
    inventoryPermanentTransfer() {
      return this.$store.state[this.MODULE_NAME].inventoryPermanentTransferFormByAsset
    },
    inventoryTransferOrderInformationByAssetId() {
      return this.$store.state[this.MODULE_NAME].transferOrderScheduledInformation
    },
    inventoryPermanentTransferClone() {
      return this.$store.state.cloneData.transferOrderClone
    },
    isFormChanged() {
      return JSON.stringify(this.inventoryPermanentTransfer) !== this.inventoryPermanentTransferClone
    },
    formattedDate() {
      const date = {
        fullYear: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).getFullYear(),
        day: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).getDate(),
        month: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).getMonth() + 1,
      }
      const { fullYear, day, month } = date
      const secondMonth = month < 10 ? `0${month}` : month
      const secondDay = day < 10 ? `0${day}` : day
      return [`${fullYear}`, `${secondMonth}`, `${secondDay}`].join('-')
    },
    formattedDateWithString() {
      const date = {
        fullYear: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).getFullYear(),
        day: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).getDate(),
        month: new Date(this.inventoryPermanentTransfer.scheduled_dispatch).toLocaleString('default', { month: 'long' }),
        transferTime: this.inventoryPermanentTransfer.transfer_time,
      }
      const {
        fullYear, day, month, transferTime,
      } = date
      return [`${month}`, `${day}`, `${fullYear}`, `${transferTime}`].join(' ')
    },
    inventory() {
      return this.$store.state[this.MODULE_NAME].product
    },
  },
  created() {
    const { id, product_id } = this.$route.params
    const data = {
      id,
      type: 'assets',
    }
    this.$store.dispatch(`${this.MODULE_NAME}/getDetails`, data).then(res => {
      const { data } = res.data
      const mappedArray = data.assets.length > 0 ? data.assets?.map(item => ({ ...item, amount: 1 })) : []
      const transferCreateData = {
        transfer_time: '',
        scheduled_dispatch: new Date(),
        purpose: '',
        assets: mappedArray,
        spec_date_and_time: this.SPECIFIC_DATE,
      }
      this.$store.commit(`${this.MODULE_NAME}/SET_PERMANENT_TRANSFER_FORM_BY_ASSET_ID`, transferCreateData)
      this.$store.commit('cloneData/SET_TRANSFER_ORDER_BY_CLONE', transferCreateData)
      this.$store.commit(`${this.MODULE_NAME}/SET_TRANSFER_ORDER_QUOTE`, {
        state: data.state,
        status: data.status,
      })
    }).catch(err => {
      console.log(err)
    })
    // const transferData = {
    //   id: product_id,
    //   type: 'assets',
    // }
    // this.$store.dispatch(`${this.MODULE_NAME}/getTransferOrder`, transferData).then(res => {
    //   const { data } = res
    //   const transferCreateData = {
    //     transfer_time: '',
    //     scheduled_dispatch: new Date(),
    //     purpose: '',
    //     assets: data.assets,
    //     spec_date_and_time: this.SPECIFIC_DATE,
    //   }
    //   this.$store.commit(`${this.MODULE_NAME}/SET_PERMANENT_TRANSFER_FORM_BY_ASSET_ID`, transferCreateData)
    // }).catch(err => {
    //   console.log(err)
    // })
    this.$store.dispatch(`${this.MODULE_NAME}/getStockProduct`, { id: product_id })
  },
  methods: {
    backToList() {
      this.$router.back()
    },
    cancelBtn() {
      this.$store.commit(`${this.MODULE_NAME}/SET_PERMANENT_TRANSFER_FORM_BY_ASSET_ID`, {})
      this.$store.commit('cloneData/SET_TRANSFER_ORDER_BY_CLONE', {})
      this.$store.commit(`${this.MODULE_NAME}/SET_TRANSFER_ORDER_QUOTE`, {})
    },
    goBack() {
      this.$bvModal.hide('scheduled-transfer-order')
    },
    submitFormSchedule(method) {
      const transferTime = String(`${this.inventoryPermanentTransfer.transfer_time.split(':')['0']}:${this.inventoryPermanentTransfer.transfer_time.split(':')['1']}`)
      const mappedAssetItems = this.inventoryPermanentTransfer.assets?.map(({ warehouse_location_id, warehouse_id, amount }) => ({
        warehouse_location_id, warehouse_id, amount,
      }))
      const data = this.inventoryPermanentTransfer
      const orderId = this.inventoryTransferOrderInformationByAssetId.id
      const transferOrder = {
        _id: data?.assets?.[0]?.asset_id ?? 0,
        type: 'assets',
        receiving_warehouse: data.receiving_warehouse.id,
        scheduled_dispatch: String(this.formattedDate),
        purpose: data.purpose,
        transfer_time: String(transferTime),
        amount: data?.assets?.[0]?.amount ?? 0,
        method,
        unite_id: orderId,
      }
      this.$store.dispatch(`${this.MODULE_NAME}/createTransferOrder`, { transferOrder }).then(res => {
        const { data } = res?.data
        this.$router.push({ name: 'scheduled-transfer-order-page-by-asset', params: { id: data.id } })
      }).catch(err => {
        this.errorNotification(this, err)
      }).finally(() => {
      })
    },
    submitForce() {
      const transferTime = String(`${this.inventoryPermanentTransfer.transfer_time.split(':')['0']}:${this.inventoryPermanentTransfer.transfer_time.split(':')['1']}`)
      const mappedAssetItems = this.inventoryPermanentTransfer.assets?.map(({ warehouse_location_id, warehouse_id, amount }) => ({
        warehouse_location_id, warehouse_id, amount,
      }))
      const data = this.inventoryPermanentTransfer
      const { id } = this.$route.params
      const transferOrder = {
        _id: id,
        type: 'assets',
        receiving_warehouse: data.receiving_warehouse.id,
        scheduled_dispatch: String(this.formattedDate),
        purpose: data.purpose,
        transfer_time: String(transferTime),
        amount: mappedAssetItems?.find(i => i.amount > 0)?.amount ?? 0,
        method: 'force',
      }
      this.$store.dispatch(`${this.MODULE_NAME}/createTransferOrder`, { transferOrder }).then(res => {
        const { data } = res
        this.$router.push({ name: 'scheduled-transfer-order-page-by-asset', params: { id: data.id } })
      })
    },
    submit() {
      this.$refs.refPermanentTransferCreateByAsset.validate()
        .then(success => {
          if (success) {
            const transferTime = String(`${this.inventoryPermanentTransfer.transfer_time.split(':')['0']}:${this.inventoryPermanentTransfer.transfer_time.split(':')['1']}`)
            const mappedAssetItems = this.inventoryPermanentTransfer.assets?.map(({ warehouse_location_id, warehouse_id, amount }) => ({
              warehouse_location_id, warehouse_id, amount,
            }))
            const data = this.inventoryPermanentTransfer
            const { id } = this.$route.params
            const transferOrder = {
              _id: id,
              type: 'assets',
              receiving_warehouse: data.receiving_warehouse.id,
              scheduled_dispatch: String(this.formattedDate),
              purpose: data.purpose,
              transfer_time: String(transferTime),
              amount: mappedAssetItems?.find(i => i.amount > 0)?.amount ?? 0,
              method: '',
            }
            this.$store.dispatch(`${this.MODULE_NAME}/createTransferOrder`, { transferOrder }).then(res => {
              const { data } = res.data
              if (data.method === 'unite') {
                const {
                  id, order_id, scheduled_dispatch, transfer_time, items, requested_by,
                  order_type,
                } = data
                // eslint-disable-next-line camelcase
                const scheduledTime = `${new Date(scheduled_dispatch).toLocaleString('default', { month: 'long', year: 'numeric', day: 'numeric' })} ${transfer_time}`
                const selectObject = {
                  id,
                  order_id,
                  scheduled_dispatch: scheduledTime,
                  item_ct: items,
                  requested_by,
                  order_type,
                  inventory_item: data?.inventory_item,
                }
                this.$store.commit(`${this.MODULE_NAME}/SET_TRANSFER_ORDER_SCHEDULED`, selectObject)
                this.$bvModal.show('scheduled-transfer-order')
              } else if (data.method === 'force') {
                this.$bvModal.show('transfer-conflicts-with-orders')
              } else {
                this.$router.push({ name: 'scheduled-transfer-order-page-by-asset', params: { id: data.id } })
              }
            }).catch(err => {
              console.log(err)
            })
          } else {
            scrollToError(this, this.$refs.refPermanentTransferCreateByAsset)
          }
        })
    },
  },
  setup() {
    const {
      MODULE_NAME, SPECIFIC_DATE, modalTableColumns, ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE,
    } = config()

    return {
      MODULE_NAME,
      SPECIFIC_DATE,
      modalTableColumns,
      ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE,
    }
  },
}
</script>

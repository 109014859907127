<template>
  <div class="bg-white mb-1 px-1 mt-1 rounded-lg">
    <h2 class="font-weight-bolder font-medium-5 pt-2">
      {{ $t('Asset') }}
    </h2>
    <l-table-list-collector
      ref="lTableRef"
      :is-searchable="false"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :is-footer="true"
      :fetched-data="inventoryPermanentTransfer.assets"
      :can-create="ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE"
      class="pb-1"
    >
      <template #pagination>
        {{ }}
      </template>
      <template #cell(actions)="{}">
        <div
          class="d-flex justify-content-center"
        >
          <Can
            :i="ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE.action"
            :a="ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE.subject"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="AlignLeftIcon"
                class="cursor-pointer border-dotted mr-1"
                style="width: 40px; height: 28px; padding: 5px"
              />
              <feather-icon
                icon="LCalendarsIcon"
                class="cursor-pointer border-dotted"
                style="width: 40px; height: 28px; padding: 5px"
                @click="gotoNewPage({ name: 'home-inventory-calendar', params: { id: itemId } }, $event)"
              />
              <span class="ml-1">
                <feather-icon
                  icon="LBoxSentIcon"
                  size="20"
                  color="green"
                />
              </span>
            </span>
          </Can>
        </div>
      </template>
      <template #cell(asset_id)="{data: {item}}">
        <div class="pl-2">
          {{ getValueFromGivenObjectByKey(item, 'asset_id', '—') }}
        </div>
      </template>
      <template #cell(warehouse)="{data: {item}}">
        <div class="pl-2">
          {{ getValueFromGivenObjectByKey(item, 'warehouse', '—') }}
        </div>
      </template>
      <template #cell(stock)="{data: {item}}">
        <div class="pl-3">
          {{ getValueFromGivenObjectByKey(item, 'stock', '—') }}
        </div>

      </template>
    </l-table-list-collector>
  </div>
</template>
<script>

import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from '../../../create/createTransferByAssetID/config'

export default {
  components: { LTableListCollector },
  computed: {
    inventoryPermanentTransfer() {
      return this.$store.state[this.MODULE_NAME].inventoryPermanentTransferFormByAsset
    },
    itemId() {
      return this.$route.params.id
    },
  },
  methods: {
    getValueFromGivenObjectByKey,
    fetchedItem(data) {
      const newArr = []
      data.forEach(itemOne => {
        itemOne.data.forEach(({
          stock, warehouse, sku, id,
        }) => {
          newArr.push({
            stock, warehouse, sku, asset_id: id,
          })
        })
      })
      return newArr
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns, ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE } = config()

    return {
      MODULE_NAME,
      tableColumns,
      ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE,
    }
  },

}
</script>

import {
  FLAT_PICKER,
  L_TIME_PICKER_INPUT,
  RADIO_GROUP,
  SELECT_INPUT,
  TEXTAREA_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'orders'
  const AS_SOON = 'as_soon'
  const SPECIFIC_DATE = 'specific_date'
  const ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE = { action: 'change', subject: 'Catalog' }
  const quoteStatusBar = [
    {
      icon: 'LInOutIcon',
      title: 'Schedule',
      iconSize: '22',
    },
    {
      icon: 'LTruckIcon',
      title: 'Dispatch',
      iconSize: '22',
    },
    {
      icon: 'LWarehouseIcon',
      title: 'Receive',
      iconSize: '22',
    },
  ]
  const ORDERS_STATUS_NO_ACTION = Object.freeze({
    id: 'no_action',
    status: 0,
    title: 'To be done',
    style: {
      color: '#D9D9D9',
      styleName: 'ORDERS_STATUS_NO_ACTION',
    },
  })
  const ORDERS_STATUS_IN_PROGRESS = Object.freeze({
    id: 'in_progress',
    status: 1,
    title: 'In progress',
    style: {
      color: '#4E1476',
      styleName: 'ORDERS_STATUS_IN_PROGRESS',
    },
  })
  const ORDERS_STATUS_ATTENTION = Object.freeze({
    id: 'attention',
    status: 2,
    title: 'Attention!',
    style: {
      color: '#BB2124',
      styleName: 'ORDERS_STATUS_ATTENTION',
    },
  })
  const ORDERS_STATUS_WARNING = Object.freeze({
    id: 'warning',
    status: 3,
    title: 'Warning',
    style: {
      color: '#F0AD4E',
      styleName: 'ORDERS_STATUS_WARNING',
    },
  })
  const ORDERS_STATUS_READY = Object.freeze({
    id: 'ready',
    status: 4,
    title: 'Ready',
    style: {
      color: '#00B139',
      styleName: 'ORDERS_STATUS_READY',
    },
  })
  const ORDERS_STATUS_VOID = Object.freeze({
    id: 'void',
    status: 5,
    title: 'Void',
    style: {
      color: '#000000',
      styleName: 'ORDERS_STATUS_VOID',
    },
  })

  const orderStatesAndStatuses = [
    { ...ORDERS_STATUS_NO_ACTION },
    { ...ORDERS_STATUS_IN_PROGRESS },
    { ...ORDERS_STATUS_ATTENTION },
    { ...ORDERS_STATUS_WARNING },
    { ...ORDERS_STATUS_READY },
    { ...ORDERS_STATUS_VOID },
  ]

  const autoSuggestTableColumns = [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
  ]

  const permanentTransferCreateFields = {
    spec_date_and_time: {
      type: RADIO_GROUP,
      label: 'Specific Date and Time',
      placeholder: 'Asset(s) allocation',
      value: 'specific_date',
    },
    scheduled_dispatch: {
      type: FLAT_PICKER,
      label: 'Scheduled Dispatch',
      rules: 'required',
      hasOperationHours: true,
      options: {
        config: {
          'is-calendar-icon': true,
          minDate: new Date(),
        },
      },
    },
    receiving_warehouse: {
      type: SELECT_INPUT,
      label: 'Receiving Warehouse',
      rules: 'required',
      store: 'warehouseList',
      options: {
        label: 'label',
      },
    },
    as_soon_as_possible: {
      type: RADIO_GROUP,
      label: 'As Soon as Possible',
      value: 'as_soon',
    },
    transfer_time: {
      type: L_TIME_PICKER_INPUT,
      label: 'Transfer Time',
      rules: 'required',
    },
    purpose: {
      type: TEXTAREA_INPUT,
      label: 'Purpose',
      rules: 'required',
      options: {
        placeholder: 'Write notes here...',
        rows: '4',
      },
    },
  }

  const tableColumns = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'warehouse',
      label: 'Origin Warehouse',
      thStyle: { width: '15%,' },
    },
    {
      key: 'stock',
      label: 'Stock',
      thStyle: { width: '7%' },
      tBodyThStyle: { textAlign: 'center' },
    },
    {
      key: 'available',
      label: 'Available',
      thStyle: { width: '10%' },
    },
    {
      key: 'amount_to_transfer',
      label: 'Amount to Transfer',
      thStyle: { width: '45%' },
    },
  ]
  const modalTableColumns = [
    {
      key: 'order_id',
      label: 'Order No.',
      thStyle: { width: '20%' },
      tBodyThStyle: { padding: '0px' },
    },
    {
      key: 'scheduled',
      label: 'Scheduled',
      thStyle: { width: '30%', textAlign: 'left' },
    },
    {
      key: 'item_ct',
      label: 'Item Ct',
      thStyle: { width: '50%', textAlign: 'left' },
      tBodyThStyle: { textAlign: 'left' },
    },
  ]
  function checkItemStateAndStatus(item, index, stateAndStatuses) {
    if (!item) {
      return 'ORDERS_STATUS_NO_ACTION'
    }
    // eslint-disable-next-line no-nested-ternary,no-undef
    return item.state === index
      ? `${stateAndStatuses.find(({ status }) => status === item.status).style.styleName}`
      : index < item.state ? 'ORDERS_STATUS_READY' : 'ORDERS_STATUS_NO_ACTION'
  }

  return {
    quoteStatusBar,
    checkItemStateAndStatus,
    orderStatesAndStatuses,
    permanentTransferCreateFields,
    ACCESS_ABILITY_INVENTORY_PERMANENT_CREATE,
    autoSuggestTableColumns,
    MODULE_NAME,
    tableColumns,
    modalTableColumns,
    AS_SOON,
    SPECIFIC_DATE,
  }
}
